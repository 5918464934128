.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .coming-soon-header {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .coming-soon-text {
    font-size: 1.5rem;
    text-align: center;
    max-width: 80%;
  }

  h1 {
    color: white;
  }
  