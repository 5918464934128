#header {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 60px;
  }
  
  #header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo a {
    font-size: 24px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  #navbar {
    display: flex;
    align-items: center;
  }
  
  #navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  #navbar ul li {
    margin-left: 30px;
  }
  
  #navbar ul li:first-child {
    margin-left: 0;
  }
  
  #navbar ul li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
  }
  
  #navbar ul li a.active {
    color: #007bff;
  }
  
  #navbar .mobile-nav-toggle {
    font-size: 24px;
    color: #000;
    margin-left: 30px;
    cursor: pointer;
    display: none;
  }
  
  @media (max-width: 991px) {
    #navbar ul {
      flex-direction: column;
      position: fixed;
      top: 60px;
      left: -100%;
      width: 100%;
      height: calc(100vh - 60px);
      overflow-y: auto;
      background-color: #fff;
      transition: 0.3s;
    }
  
    #navbar ul.show {
      left: 0;
    }
  
    #navbar ul li {
      width: 100%;
      margin-left: 0;
    }
  
    #navbar ul li a {
      color: #000;
      font-size: 20px;
      text-align: center;
      padding: 15px;
      display: block;
      border-bottom: 1px solid #eee;
    }
  
    #navbar .mobile-nav-toggle {
      display: block;
    }
  }
  