/* Global styles */

body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    background-image: url(/src/images/background.jpg);
  }
  
  /* Header styles */
  header {
    background-color: #2c3e50;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  /* Navigation styles */
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
  }
  
  nav a:hover {
    color: #bdc3c7;
  }
  
  /* Main section styles */
  main {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  p {
    color: white;
  }
  
  /* Card styles */
  .card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin: 20px;
    padding: 20px;
    width: 300px;
  }
  
  .card h2 {
    color: #2c3e50;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .card p {
    color: white;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Footer styles */
  footer {
    background-color: #2c3e50;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  footer p {
    margin: 0;
  }
  